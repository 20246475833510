import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../../components/layout.css"
import BlogListings from "../../components/BlogListings"

// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <div className="container">
        <div className="row">
        <div className="blog-posts">
        <hr />
        <pre>jpaek.com/design_patterns</pre>
        <p>Design patterns were popularized by Erich Gamma, Richard Helm, Ralph Johnson, and John Vlissides. Commonly referred to as Gang Of Four (GoF).  They popularized a book titled "Design Patterns: Elements of Reusable Object-Oriented Software" in 1994.</p>
        <b>Why I'm writing this</b>
        <p>I started work as an instructional associate for the software and architecture course soon after graduating (masters in cs at Georgia Tech). My hopes are it can serve as a good resource for students. Also, good chance to deep dive into every pattern. </p>
        <b>Update</b>
        <p>Moved and invited to join natural language processing the following semsester.  I think quite exciting here at the intersection of AI and machine learning.  So a pause on these architectural patterns for the time being while I settle in.</p>
        <hr / >
        </div>
        </div>
        <div className="row">
        <div className="col">
        <BlogListings posts={posts}/>
        </div>
        <div className="col-4">
        <div className="">
        <h5 className="">Creational Patterns</h5>
        <ul>
        <li>Abstract Factory</li>
        <li>Builder</li>
        <li>Factory Method</li>
        <li>Prototype</li>
        <li>Singleton</li>
        </ul>

        <h5 className="">Structural Patterns</h5>
        <ul>
        <li>Adapater</li>
        <li>Bridge</li>
        <li>Composite</li>
        <li>Decorator</li>
        <li>Facade</li>
        <li>Flyweight</li>
        <li>Proxy</li>
        </ul>
        <h5 className="">Behavorial Patterns</h5>
        <ul>
        <li>Chain of Responsibility</li>
        <li>Command</li>
        <li>Interpreter</li>
        <li>Iterator</li>
        <li>Mediator</li>
        <li>Memento</li>
        <li>Observer</li>
        <li>State</li>
        <li>Strategy</li>
        <li>Template Method</li>
        <li>Visitor</li>
        </ul>
        </div>
        <h5 className="">Additional Topics</h5>
        <ul> 
        <li>Favor Composition Over Inheritance</li>
        </ul>
        </div>
        </div>
        <hr />
        </div> 
    )
}

// Gatsy will introspect this query "myDesignPatternIndexQuery".  This is an index.
export const pageQuery = graphql`
  query myDesignPatternsIndexQuery {
      allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fields: { collection: { eq: "design_patterns" } } }) {
              edges {
                  node {
                      excerpt(pruneLength: 250)
                      id
                      fields {
                          collection
                      }
                      frontmatter {
                          title
                          date(formatString: "MMMM DD, YYYY")
                          path
                      }
                  }
              }
          }
  }
`

